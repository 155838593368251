import { Spin } from "antd";
import { get } from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import { updateActiveId } from "../../../redux/actions/product-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";

import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import RequirePairingAuthWrapper from "../../general/components/RequirePairingAuthWrapper";
import IncomeCapacityNew from "../components/IncomeCapacityNew";

// markup
const RobotPage = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const user = get(props, "user.user");

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <RequirePairingAuthWrapper>
            <NavHeader
              showBack={true}
              suffix={
                <React.Fragment>
                  <div className="justify-end flex items-center">
                    <span className="inline-block text-base text-right font-semibold">
                      {t("dynamic", sourceKey.pairing)}
                    </span>
                  </div>
                </React.Fragment>
              }
            >
              <div className="">
                <div className="px-2">
                  <IncomeCapacityNew
                    type="dynamicCap"
                    user={user}
                    showTransaction
                  />
                </div>
              </div>
            </NavHeader>
          </RequirePairingAuthWrapper>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  updateActiveId,
};
export default connect(mapStateToProps, mapDispatchToProps)(RobotPage);
