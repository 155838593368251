import * as React from "react";
import SharedHeader from "../components/general/components/SharedHeader";
import DynamicCapacityPage from "../components/rewardExplore/pages/DynamicCapacityPage";

// markup
const APIKey = () => {
  return (
    <React.Fragment>
      <SharedHeader />
      <DynamicCapacityPage />
    </React.Fragment>
  );
};

export default APIKey;
